import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as DietsApi from "../api/dietsApi";
import addInSortArray from "../helpers/addInSortArray";
import {ADD_TEMPLATE_ERROR_MESSAGE} from "../constants/diet";
import { EError } from "../constants/enums";

export const loadDietTemplates = createAsyncThunk(
  "dietTemplates/loadDietTemplates",
  (args) => DietsApi.loadDietTemplatesWithCache(args)
);

export const addDietTemplates = createAsyncThunk(
  "dietTemplates/addDietTemplates",
  DietsApi.addDietTemplates
);

export const deleteDietTemplates = createAsyncThunk(
  "dietTemplates/deleteDietTemplates",
  DietsApi.deleteDietTemplates
);

const dietTemplatesSlice = createSlice({
  name: "dietTemplates",
  initialState: {
    items: null,
    removableItem: null,
    loading: false,
    success: false,
    successfullyDietTemplateDeleted: false,
    successfullyDietTemplateAdded: false,
    error: "",
  },
  reducers: {
    setRemovableItem(state, action) {
      state.removableItem = action.payload;
    },
    clearRemovableItem(state) {
      state.removableItem = null;
    },
    clearSuccessfullyAddDietTemplate(state) {
      state.successfullyDietTemplateAdded = false;
    },
    clearSuccessfullyDeleteDietTemplate(state) {
      state.successfullyDietTemplateDeleted = false;
    },
    clearError(state) {
      state.error = "";
    }
  },
  extraReducers: {
    [loadDietTemplates.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [loadDietTemplates.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = "";
      state.items = action.payload;
    },
    [loadDietTemplates.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload || EError.default_error;
    },
    [deleteDietTemplates.pending]: (state) => {
      state.successfullyDietTemplateDeleted = false;
    },
    [deleteDietTemplates.fulfilled]: (state, action) => {
      state.successfullyDietTemplateDeleted = true;
      state.error = "";
      state.items = state.items.filter((i) => i.id !== action.meta.arg);
      state.removableItem = null;
    },
    [deleteDietTemplates.rejected]: (state, action) => {
      state.successfullyDietTemplateDeleted = false;
      state.error = action.payload || EError.default_error;
    },
    [addDietTemplates.pending]: (state) => {
      state.successfullyDietTemplateAdded = false;
    },
    [addDietTemplates.fulfilled]: (state, action) => {
      state.successfullyDietTemplateAdded = true;
      state.error = "";
      state.items = addInSortArray(state.items, action.payload, "name");
    },
    [addDietTemplates.rejected]: (state) => {
      state.successfullyDietTemplateAdded = false;
      state.error = ADD_TEMPLATE_ERROR_MESSAGE;
    },
  },
});

export const {
  setRemovableItem,
  clearRemovableItem,
  clearSuccessfullyAddDietTemplate,
  clearSuccessfullyDeleteDietTemplate,
  clearError
} = dietTemplatesSlice.actions;

export default dietTemplatesSlice.reducer;
