import {createSlice} from "@reduxjs/toolkit";

const errorsSlice = createSlice({
  name: "errors",
  initialState: {
    errorStatus: null,
    errorMessage: null,
  },
  reducers: {
    setErrorStatus(state, action) {
      state.errorStatus = action.payload;
    },
    setErrorMessage(state, action) {
      state.errorMessage = action.payload;
    }
  }
})

export const {
  setErrorStatus,
  setErrorMessage
} = errorsSlice.actions;

export default errorsSlice.reducer;
