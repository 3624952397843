import cloneDeep from "lodash/cloneDeep";
import groupBy from "lodash/groupBy";
import isBoolean from "lodash/isBoolean";
import isEqual from "lodash/isEqual";
import isInteger from "lodash/isInteger";
import isNull from "lodash/isNull";
import isObject from "lodash/isObject";
import isUndefined from "lodash/isUndefined";
import transform from "lodash/transform";
import moment from "moment";

import {
  DATE_FORMAT_ON_BACKEND,
  DATE_FORMAT_ON_FRONTEND,
  FULL_MONTH_DATE_FORMAT_ON_FRONTEND,
  SHORT_DATE_DOTS_FORMAT_ON_FRONTEND,
  SHORT_DATE_FORMAT_ON_FRONTEND,
  FANCY_DATE_FORMAT_ON_FRONTEND,
  USER_ROLES,
  LONG_DATE_DOTS_FORMAT_ON_FRONTEND,
  UTC_FORMAT_ON_BACKEND,
  UTC_FORMAT_TO_BACKEND,
} from "../constants/common";
import { REGEXP_NOT_NUM, REGEXP_NOT_INT } from "../constants/regexp";
import {
  EDITED_TYPES,
  PROGRAM_REPS_EDIT,
  PROGRAM_WEIGHT_PERCENT_EDIT,
} from "../constants/templates";
import { FEMALE } from "../constants/user";
import { PB_STEP_THRESHOLD } from "../constants/training";
import { uniq, filter, flatMap } from "lodash";

export const getFullName = (data) => {
  if (!isObject(data)) {
    return "";
  }
  if ("first_name" in data && "last_name" in data) {
    return `${data.first_name || ""} ${data.last_name || ""}`.trim();
  }
  if ("firstName" in data && "lastName" in data) {
    return `${data.firstName || ""} ${data.lastName || ""}`.trim();
  }
  if ("full_name" in data) {
    return data.full_name;
  }
  return "";
};

const prepareDateForInput = ({ prevVal, length, needStartSlash, skipRest, data }) => {
  if(prevVal?.length < data?.length) {
    if(data[data.length - 1] === "/") return prevVal;
    const isDateCorrect = data.split("/").every(i => isInteger(+i) && i !== ".")
    if (!isDateCorrect) return prevVal
  }
  const deleted = prevVal.length > length;

  data = data.split("/").map((item) =>  {
    if (item.length > 2) {
      return `${item[0] + item[2]}`
    }
    return item
  }).join("/")

  if(length === 1 && data === " ") {
    data = "0";
    skipRest = true;
  }

  if(length === 2 && data[1] === " ") {
    data = "01/";
    skipRest = true;
  }

  if (length === 4 && data[3] === " ") {
    data = `${data.slice(0,2)}/0`;
  }

  if(length === 5 && data[4] === " ") {
    data = `${data.slice(0,2)}/01/`;
    skipRest = true;
  }

  if(length === 9) return prevVal.slice(0,7) + data.slice(-1);

  if((length === 2 || length === 5) && deleted) {
    data = data.slice(0,-1)
    skipRest = true
  }

  if(length < 3 && !skipRest && !deleted) {
    if(length < 2 && data > 3) {
      data = "0" + data;
      needStartSlash = true;
    }

    if(data > 31) data = 31;

    if(length === 2) needStartSlash = true;
  }

  if(length < 6 && length > 3 && !skipRest && !deleted ) {
    const month = data.slice(3,5);

    if (length < 5 && month > 2) {
      data = data.slice(0, 3) + `0${month}`;
      needStartSlash = true
    }

    if(length === 5) {
      needStartSlash = true

      if(month > 12 ) {
        data = data.slice(0, 3) + 12;
      }
    }
  }

  return needStartSlash ? data + "/" : data
}


export const setDataWithSlashes = (data, setter) => {
  const length = data.length;
  let needStartSlash = false;
  let skipRest = false;

  setter((prevVal) => prepareDateForInput({ prevVal, length, needStartSlash, skipRest, data }));
}

export const isFemale = (data) => data?.gender?.toLowerCase() === FEMALE;

export const upperCaseFirst = (text) => {
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
};

export const getIdArray = (list) => list.map((item) => item.id);

// TODO: перестать юзать класс и вынести просто функцию.
export class BasicHelper {
  static roundTo(number, precision = 0) {
    let a = Math.pow(10, precision);
    return Math.round(number * a) / a;
  }
}

export const filterList = (list, filter, fieldname = "name") => {
  const search = filter.toLowerCase().split(" ").join("");
  return list.filter((item) =>
    item[fieldname].toLowerCase().split(" ").join("").includes(search)
  );
};

export const prepareDateForBack = (val) =>
  moment(val, DATE_FORMAT_ON_FRONTEND).format(DATE_FORMAT_ON_BACKEND);

export const prepareDateForBackUTC = (val) =>
  moment(val, DATE_FORMAT_ON_FRONTEND).utc().format(UTC_FORMAT_TO_BACKEND);

export const prepareDateForFront = (val, replaceInvalidDate = true) => {
  const dateFromBackend = moment(val, DATE_FORMAT_ON_BACKEND);
  const isValidValue = dateFromBackend.isValid();
  if (replaceInvalidDate && !isValidValue) return "";
  return dateFromBackend.format(DATE_FORMAT_ON_FRONTEND);
};

export const prepareShortDateForFront = (val) =>
  moment(val, DATE_FORMAT_ON_BACKEND).format(SHORT_DATE_FORMAT_ON_FRONTEND);

export const prepareShortLocalDateForFront = (val) =>
  moment
    .utc(val, UTC_FORMAT_ON_BACKEND)
    .local()
    .format(SHORT_DATE_FORMAT_ON_FRONTEND);

export const prepareFullMonthDateForFront = (val) =>
  moment(val, DATE_FORMAT_ON_BACKEND).format(
    FULL_MONTH_DATE_FORMAT_ON_FRONTEND
  );

export const prepareShortDateDotsForFront = (val) =>
  moment(val, DATE_FORMAT_ON_BACKEND).format(
    SHORT_DATE_DOTS_FORMAT_ON_FRONTEND
  );

export const prepareShortLocalDateDotsForFront = (val) =>
  moment(val, UTC_FORMAT_ON_BACKEND)
    .local()
    .format(SHORT_DATE_DOTS_FORMAT_ON_FRONTEND);

export const prepareFancyDateForFront = (val) =>
  moment(val, DATE_FORMAT_ON_BACKEND)
    .format(FANCY_DATE_FORMAT_ON_FRONTEND)
    .toLowerCase();

export const prepareLocalFancyDateForFront = (val) =>
  moment(val, UTC_FORMAT_ON_BACKEND)
    .local()
    .format(FANCY_DATE_FORMAT_ON_FRONTEND)
    .toLowerCase();

export const prepareLocalFromNow = (val) =>
  moment(val, UTC_FORMAT_ON_BACKEND).local().fromNow();

export const prepareShortDateDotsForFrontFromLong = (val) =>
  moment(val, LONG_DATE_DOTS_FORMAT_ON_FRONTEND).format(
    SHORT_DATE_DOTS_FORMAT_ON_FRONTEND
  );

export const bodyPartsListSymbol = (index, item, daysBodyParts, noHtml) => {
  if (index === daysBodyParts.length - 2 && noHtml) {
    return " &";
  } else if (index === daysBodyParts.length - 2) {
    return <span>&nbsp;&</span>;
  } else if (index === daysBodyParts.length - 1 || !item) {
    return ""
  } else {
    return ",";
  };
};


export const getWorkoutBodyparts = (set) =>
  [...new Set(set?.flatMap(item =>
    item.body_parts?.filter(part => part !== "unspecified")))];

// юзаем для подготовки данных сущности для вставки в инит значения формика
export const deleteTrashFieldForFormik = (data) => {
  data = cloneDeep(data);
  delete data?.created_at;
  delete data?.updated_at;
  delete data?.is_active;
  delete data?.is_deleted;
  delete data?.id;
  delete data?.body;
  data = setBlankInsteadOfNULL(data);
  data = numberToString(data);
  return data;
};

export const deleteTrashFieldForClientFormik = (data) => {
  data = cloneDeep(data);
  return {
    gender: data.gender,
    mass_unit: data.mass_unit,
    company: data.company,
    trainers: data.trainers,
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    birth: data.birth,
    height: data.height,
    phone: data.phone,
    password: data.password,
  };
};

export const prepareClientDataForSend = (sendData) => {
  if (sendData?.birth) {
    sendData.birth = prepareDateForBack(sendData.birth);
  }
  if (sendData?.company) {
    sendData.company = Number(sendData.company.id);
  }
  if (sendData?.trainers) {
    sendData.trainers = getIdArray(sendData.trainers.filter(Boolean));
  }
  sendData = setNullInsteadOfBlank(sendData);
  delete sendData.repeat_password;
  return sendData;
};

export const prepareTrainersForSend = (sendData, values, initialValues) => {
  if (!isEqual(values.trainers, initialValues.trainers)) {
    sendData.trainers = values.trainers;
  } // нужно посылать весь список тренеров, не только измененных
  return sendData;
};

export const clearEmptyField = (data) => {
  Object.keys(data).map((key) => {
    if (
      !isBoolean(data[key]) &&
      (isNull(data[key]) ||
        isUndefined(data[key]) ||
        data[key] === "")
    ) {
      delete data[key];
    }
  });
  return data;
};


/**
 * checks if object's fields don't have any ""/null/undefined
 *
 * @param {Object} obj
 * @return {boolean}
 */
export const isObjectFulfilled = (obj) => {
  return !Object.values(obj).some(
    (val) => val === "" || isNull(val) || isUndefined(val)
  );
};

/**
 * returns true if field exists somewhere in obj
 * @param {object} obj
 * @param {string} field
 * @returns {boolean}
 */
export const checkNestedField = (obj, field) => {
  if (field in obj) {
    return true;
  }

  return Object.values(obj)
    .filter(isObject)
    .some((objVal) => checkNestedField(objVal, field));
};

export const setBlankInsteadOfNULL = (data) => {
  data = cloneDeep(data);
  Object.keys(data).map((key) => {
    if (isNull(data[key])) {
      data[key] = "";
    }
  });
  return data;
};

export const setNullInsteadOfBlank = (data, clearEmptyData = true) => {
  data = cloneDeep(data);
  if (clearEmptyData) {
    Object.keys(data).map((key) => {
      if (data[key] === "") {
        data[key] = null;
      }
    });
  }

  return data;
};

export const numberToString = (data) => {
  data = cloneDeep(data);
  Object.keys(data).map((key) => {
    if (typeof data[key] === "number") {
      data[key] = data[key].toString();
    }
  });
  return data;
};

// https://gist.github.com/Yimiprod/7ee176597fef230d1451
// сравнение двух объектов и возврат разницы между ними.
// юзаем когда изменяем сущность и надо слать на бек только те поля,
// что изменились
export const getDifferenceField = (object, base, isSuperUser) => {
  const changes = (object, base) => {
    return transform(object, (result, value, key) => {
      if (isSuperUser && key === "bg_file") return;
      if (!isEqual(value, base[key])) {
        result[key] =
        isObject(value) && isObject(base[key])
          ? changes(value, base[key])
          : value;
      }
    });
  };
  return changes(object, base);
};

/** @param {string} string */
export const onlyNumbers = (string) =>
  string.replace(new RegExp(REGEXP_NOT_NUM), "");

/** @param {string} string */
export const onlyIntNumbers = (string) =>
  string.replace(new RegExp(REGEXP_NOT_INT), "");

/**
 * @param {string} string
 * @param {number} minValue
 * @param {number} maxValue
 */
export const validateIntValue = (string, minValue, maxValue) => {
  const result = string.replace(new RegExp(REGEXP_NOT_INT), "");
  if (result !== "" && (+result < minValue || +result > maxValue)) return "";
  return result;
};

/**
 * @param {string} string
 * @param {string} type
 */
export const editProgramsValue = (string, type) => {
  const isWeight = type === EDITED_TYPES.WEIGHT;
  const minValue = isWeight
    ? PROGRAM_WEIGHT_PERCENT_EDIT.MIN
    : PROGRAM_REPS_EDIT.MIN;
  const maxValue = isWeight
    ? PROGRAM_WEIGHT_PERCENT_EDIT.MAX
    : PROGRAM_REPS_EDIT.MAX;
  return validateIntValue(string, minValue, maxValue);
};

/**
 * perms filter for items
 * @param {string} userRole
 * @param {string} userId
 * @param {string} userCompanyId
 * @returns {(function(*): boolean)|{(): boolean, (): boolean}}
 */
export const permsFilter = (userRole, userId, userCompanyId) =>
  userRole === USER_ROLES.TRAINER
    ? (e) => e.owner_id === userId
    : userRole === USER_ROLES.COMPANY_ADMIN
      ? (e) => e.company_id === userCompanyId
      : userRole === USER_ROLES.SUPERUSER
        ? (e) => userId === e.owner_id
        : () => false;

export const createPermittedIdList = (
  list,
  userRole,
  userId,
  userCompanyId
) => {
  if (!Array.isArray(list) || list.length === 0) return [];
  return list
    .filter(permsFilter(userRole, userId, userCompanyId))
    .map((e) => e.id);
};

export const sortArrayOfObjectsByFieldValue = (arr, fieldName) => {
  return [...arr].sort((a, b) => a[fieldName] - b[fieldName]);
};

/**
 * @param {number} val
 * @param {number[]} arr
 * @returns {number}
 */
export const selectClosestInArray = (val, arr) => {
  return arr.reduce(
    (prev, curr) => (Math.abs(curr - val) < Math.abs(prev - val) ? curr : prev),
    val
  );
};

/**
 * increment from startValue (-1 by default) until conditionCallback(val) is true
 * @param {(number) => boolean} conditionCallback
 * @param {number?} startValue
 * @returns {number}
 */
export const incToCondition = (conditionCallback, startValue, step = 1) => {
  let inc = startValue || -1;
  while (!conditionCallback(inc)) inc += step;
  return inc;
};

/**
 * @param {number} value
 * @param {number} cap
 * @returns {number}
 */
export const capBy = (value, cap) => {
  if (value > cap) {
    return cap;
  }
  return value;
};

/**
 * 1st, 2nd, 3rd, 4th, etc.
 * @param {number} num
 * @returns {string}
 */
export const ordinalSuffixOf = (num) => {
  const dec = num % 10;
  const cen = num % 100;

  if (dec === 1 && cen !== 11) {
    return `${num}st`;
  }
  if (dec === 2 && cen !== 12) {
    return `${num}nd`;
  }
  if (dec === 3 && cen !== 13) {
    return `${num}rd`;
  }
  return `${num}th`;
};

/**
 * best, 2nd best, 3rd best, 4th best, etc.
 * @param {number} num
 * @returns {string}
 */
export const ordinalRankOf = (num) =>
  `${num !== 1 ? `${ordinalSuffixOf(num)} ` : ""}best`;

export const exerciseSetErrorsSorting = (a, b) => {
  if (a.day_index !== b.day_index) return +a.day_index - +b.day_index;
  if (a.workout_index !== b.workout_index) return +a.workout_index - +b.workout_index;
  if (a.exercise_index !== b.exercise_index) return +a.exercise_index - +b.exercise_index;
  if (a.set_index !== b.set_index) return +a.set_index - +b.set_index;
  return 0;
};

/**
 * return number hash of a string
 * @param {string} str
 * @returns {number}
 */
export const getHash = (str) => {
  return str
    .split("")
    .reduce(
      (prevHash, currVal) =>
        ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
      0
    );
};

/**
 * return hash of a string
 * @param {string} str
 * @returns {string}
 */
export const getHashString = (str) => {
  return String(getHash(str));
};

/**
 * get string of the updated URL search with new param value
 * @param {string} initialSearch
 * @param {string} paramName
 * @param {any} value
 * @returns {string}
 */
export const getUpdatedLocationSearch = (initialSearch, paramName, value) => {
  const params = new URLSearchParams(initialSearch);
  if (value === null || value === undefined || value === "") {
    params.delete(paramName);
  } else {
    params.set(paramName, value);
  }
  params.sort();
  return params.toString();
};

/**
 * get the updated array based on the update and split functions
 * @param {array} origin
 * @param {(object) => boolean} predicate
 * @param {(object) => object} update
 * @param {...any} newItems
 * @returns {array}
 */
export const getUpdatedArray = (origin, predicate, update, ...newItems) => {
  const {updated, remaining} = origin.reduce((result, item) => {
    if (predicate(item)) {
      result.updated.push(update(item));
    } else {
      result.remaining.push(item);
    }
    return result;
  }, {updated: [], remaining: []});
  return [...remaining, ...newItems, ...updated];
};

/**
 * replace element in array
 * @param {array} origin
 * @param {number} idx
 * @param {any} updatedValue
 * @returns {array}
 */
export const replace = (origin, idx, updatedValue) => {
  const updatedArray = [...origin];
  updatedArray.splice(idx, 1, updatedValue);
  return updatedArray;
};

/**
 * group the array by elements' field
 * @param {array} origin
 * @param {string} fieldName
 * @returns {array}
 */
export const groupByField = (origin, fieldName) => (
  Object.values(groupBy(origin, element => element[fieldName]))
);

export const compareDates = (a, b) =>
  moment(b?.completed_at, UTC_FORMAT_ON_BACKEND).diff(moment(a?.completed_at, UTC_FORMAT_ON_BACKEND));

const getXsrfToken = () => {
  const xsrfToken = document.cookie.split("; ").find(row => row.startsWith("csrftoken"));
  return xsrfToken ? xsrfToken.split("=")[1] : null
};

export const prepareHeaders = ({headers, method, JSON_HEADERS}) => {
  const xsrfToken = getXsrfToken();
  if(headers && method) {
    return {...headers, "X-CSRFToken": xsrfToken};
  } else if (!headers && method) {
    return {...JSON_HEADERS, "X-CSRFToken": xsrfToken};
  }
  return JSON_HEADERS
};

export const getParams = ({page, search}) => {
  const pageQuery = page > 1 ? `page=${page}` : "";
  const searchQuery = search ? `&search=${search}` : "";

  return `${pageQuery}${searchQuery}`;
};

export const editSumm = summ => {
  const arr = String(summ).split(".");
  if(arr.length === 1) {
    return arr[0] + ".00";
  } else {
    return arr[1].length === 1 ? `${arr[0]}.${arr[1]}0`: summ;
  }
}

export const isPrevOrNextPageExist = ({ page, totalCount, next, prev }) => {
  if (prev) {
    return page > 1 ? false : true;
  }
  if (next) {
    return totalCount - page * 50 < 0;
  }
};

export const handlerSelectedWeight = (weight, pb) => {
  if (pb <= PB_STEP_THRESHOLD) return weight;

  for (let i = 0; i < 1.5; i += 0.5) {
    const plus = ((weight + i) / 2.5);
    const minus = ((weight - i) / 2.5);

    if (plus % 1 === 0 || plus % 1 === 0.5) {
      return weight + i;
    } else if (minus % 1 === 0 || minus % 1 === 0.5) {
      return weight - i;
    };
  };
};

export const extractTextFromHtml = (html) => {
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(html, "text/html");
  const text = parsedHtml.documentElement.textContent.replace(/\n+/g, "");
  return text === "undefined" ? "" : text;
};

export const checkIfButtonDisabled = ({ sideBarState, sideBarType, isSmallScreen, isOpenDrawer }) => {
  if (!isSmallScreen) return sideBarState === sideBarType;

  return sideBarState === sideBarType && isOpenDrawer;
};

export const getCurrentParams = () => {
  const params = new URLSearchParams(window.location.search);

  return [...params.keys()].reduce((acc, item, index) => {
    if (index === 0) {
      return `?${item}=${params.get(item)}&`;
    };
    return acc + `${item}=${params.get(item)}&`
  }, "");
};

export function groupWorkoutsByIndex(data, field) {
  const groupedWorkouts = {};

  data.forEach(item => {
      const index = item[field];
      if (!groupedWorkouts[index]) {
          groupedWorkouts[index] = [];
      };
      groupedWorkouts[index].push(item);
  });
  return Object.values(groupedWorkouts);
}

export const dragAndDropHandler = (data, fromIdx, toIdx, field) => {
  const updatedExercises = groupWorkoutsByIndex(data, field);

  return updatedExercises.reduce((acc, item, index) => {
    if (fromIdx < toIdx) {
      if (fromIdx > index || index > toIdx) {
        return [
          ...acc,
          ...item,
        ];
      };

      if (fromIdx <= index && index !== toIdx) {
        const updatedWorkout = updatedExercises[index + 1].map((workout) => {
          return {
            ...workout,
            [field]: index,
          };
        });

        return [
          ...acc,
          ...updatedWorkout,
        ];
      };

      if (toIdx === index) {
        const updatedWorkout = updatedExercises[fromIdx].map((workout) => {
          return {
            ...workout,
            [field]: index,
          };
        });

        return [
          ...acc,
          ...updatedWorkout,
        ];
      }
      if (toIdx < index) {
        return [
          ...acc,
          ...item,
        ];
      };
    };

    if (toIdx < fromIdx) {
      if (fromIdx > index && toIdx > index) {
        return [
          ...acc,
          ...item,
        ];
      };

      if (toIdx === index) {
        const updatedWorkout = updatedExercises[fromIdx].map((workout) => {
          return {
            ...workout,
            [field]: index,
          };
        });

        return [
          ...acc,
          ...updatedWorkout,
        ];
      };


      if (index > toIdx && index < fromIdx || fromIdx === index) {
        const updatedWorkout = updatedExercises[index - 1].map((workout) => {
          return {
            ...workout,
            [field]: index,
          };
        });

        return [
          ...acc,
          ...updatedWorkout,
        ];
      };

      if (fromIdx < index) {
        const updatedWorkout = item.map((workout) => {
          return {
            ...workout,
            [field]: index,
          };
        });

        return [
          ...acc,
          ...updatedWorkout,
        ];
      };
    };
    return [...acc, ...item];
  }, []);
};

export const dragAndDropWorkoutHandler = (data, field, fromIdx, toIdx) => {
  const newData = Array.from(data);

  const [movedItem] = newData.splice(fromIdx, 1);

  newData.splice(toIdx, 0, {
    ...movedItem,
    [field]: toIdx,
  });

  return newData.map((item, index) => ({
    ...item,
    [field]: index,
  }));
};

export const prepareDataForSand = (values, oldVal, isSuperUser) => {
  values = cloneDeep(values);
  if (!values?.company_admin?.password) {
    delete values?.company_admin?.password;
    delete oldVal?.company_admin?.password
  }
  delete values?.company_admin?.repeat_password;
  delete oldVal?.company_admin?.repeat_password;
  values = getDifferenceField(values, oldVal, isSuperUser);
  values = setNullInsteadOfBlank(values);
  return values;
};
