import { sessionId } from "../constants/errorLocation";
import { setErrorStatusCode } from "../redux/userSlice";
import { loadFavoriteFoodsWithCache, loadFoodsWithCache } from "./foodApi";

import myFetch, { emailHandlerError } from "./myFetch";

export const loadProfile = () => {
  return myFetch("/api/user/");
};

export const saveProfile = (body) => {
  loadFavoriteFoodsWithCache.invalidate();
  loadFoodsWithCache.invalidate();
  return myFetch(
    "/api/user/",
    { method: "PATCH", body: body },
    emailHandlerError
  ).then((res) => {
    return res;
  });
};

export const checkSessionId = async (
  { cko_session_id },
  { rejectWithValue, dispatch }
) => {
  try {
    const response = await myFetch(
      "api/payments/verify_session/",
      {
        method: "POST",
        body: {
          cko_session_id,
        },
      },
      null,
      sessionId
    );

    if (response.throwWithStatusCode) {
      throw new Error(response.message);
    }
    return response;
  } catch (error) {
    dispatch(setErrorStatusCode(400));
    return rejectWithValue(error.message);
  }
};

export const payCurrentDue = (debt) => {
  return myFetch("/api/payments/pay-debt/", {
    method: "POST",
    body: {
      debt,
    },
  });
};

export const getUsageDaysDeposit = () => {
  return myFetch("/api/end-user/");
};
