import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import * as ProgramTemplatesApi from "../api/programTemplatesApi";
import { EError } from "../constants/enums";

export const loadProgramTemplates = createAsyncThunk(
  "programTemplates/loadProgramTemplates",
  (args) => ProgramTemplatesApi.loadProgramTemplatesWithCache.call(args)
);

export const loadProgramTemplatesForCurrentUser = createAsyncThunk(
  "foods/loadProgramTemplatesForCurrentUser",
  (args) =>
    ProgramTemplatesApi.loadProgramTemplatesForCurrentUserWithCache.call(args)
);

export const loadCurrentProgramTemplate = createAsyncThunk(
  "programTemplates/loadCurrentProgramTemplate",
  (args) => ProgramTemplatesApi.loadCurrentProgramTemplateWithCache.call(args)
);

export const addProgramTemplate = createAsyncThunk(
  "programTemplates/addProgramTemplate",
  ProgramTemplatesApi.addProgramTemplate
);

export const editProgramTemplate = createAsyncThunk(
  "programTemplates/editProgramTemplate",
  ProgramTemplatesApi.editProgramTemplate
);

export const deleteProgramTemplate = createAsyncThunk(
  "programTemplates/deleteProgramTemplate",
  ProgramTemplatesApi.deleteProgramTemplate
);

export const checkTotalReps = createAsyncThunk(
  "programTemplates/checkTotalReps",
  ProgramTemplatesApi.checkTotalReps
);

export const loadProgramPreview = createAsyncThunk(
  "programs/loadProgramPreview",
  (args) => ProgramTemplatesApi.loadProgramPreviewWithCache(args)
);

export const loadHistoryProgramPreview = createAsyncThunk(
  "programs/loadHistoryProgramPreview",
  (args) => ProgramTemplatesApi.loadHistoryProgramPreviewWithCache(args)
);

const programTemplatesSlice = createSlice({
  name: "programTemplates",
  initialState: {
    items: null,
    item: null,
    itemCurrentUser: null,
    itemForDelete: null,
    loading: false,
    saving: false,
    success: false,
    error: "",
    changeAllExercise: true,
    selectedExercise: null,
    successfullyAddTemplate: false,
    successfullyDeleteTemplate: false,
    exerciseSetErrors: {},
    history: [],
    groupedByDay: [],
    totalCount: 0,
    checkingSetDetails: false,
    currentPreviewProgram: null,
  },
  reducers: {
    clearStatuses(state) {
      state.success = false;
      state.successfullyAddTemplate = false;
    },
    clearError(state) {
      state.error = "";
    },
    clearSuccess(state) {
      state.success = false;
    },
    clearSuccessfullyAddTemplate(state) {
      state.successfullyAddTemplate = false;
    },
    clearSuccessfullyDeleteTemplate(state) {
      state.successfullyDeleteTemplate = false;
    },
    setItemForDelete(state, action) {
      state.itemForDelete = action.payload;
    },
    clearItemForDelete(state) {
      state.itemForDelete = null;
    },
    clearProgramTemplates(state) {
      state.templates = null;
    },
    setChangeAllExercise(state, action) {
      state.changeAllExercise = action.payload;
    },
    setSelectedExercise(state, action) {
      state.selectedExercise = action.payload;
    },
    setExerciseSetErrors(state, action) {
      state.exerciseSetErrors[JSON.stringify(action.payload)] = action.payload;
    },
    removeExerciseSetErrors(state, action) {
      delete state.exerciseSetErrors[JSON.stringify(action.payload)];
    },
    clearExerciseSetErrors(state) {
      state.exerciseSetErrors = {};
    },
    setHistory(state, action) {
      state.history = action.payload;
    },
    setGroupedByDay(state, action) {
      state.groupedByDay = action.payload;
    },
    handleRestDay(state, action) {
      action.payload.exercises.map((item) => {
        if (item.exercise_id === null) {
          return { ...item, isRestDay: true };
        }
        return item;
      });
      state.item = action.payload;
    },
    handleCheckSetDetails(state, action) {
      state.checkingSetDetails = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [loadProgramTemplates.pending]: (state) => {
      state.loading = true;
    },
    [loadProgramTemplates.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.items = action.payload;
    },
    [loadProgramTemplates.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload || EError.default_error;
    },

    [loadProgramTemplatesForCurrentUser.pending]: (state) => {
      state.loading = true;
    },
    [loadProgramTemplatesForCurrentUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = "";
      state.itemCurrentUser = action.payload?.results;
      state.totalCount = action.payload?.count;
    },
    [loadProgramTemplatesForCurrentUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload || EError.default_error;
    },

    [loadCurrentProgramTemplate.pending]: (state) => {
      state.loading = true;
    },
    [loadCurrentProgramTemplate.fulfilled]: (state, action) => {
      programTemplatesSlice.caseReducers.handleRestDay(state, {
        payload: action.payload,
      });
      state.loading = false;
      state.error = "";
    },
    [loadCurrentProgramTemplate.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload || EError.default_error;
    },

    [editProgramTemplate.pending]: (state) => {
      state.saving = true;
    },
    [editProgramTemplate.fulfilled]: (state, action) => {
      state.saving = false;
      state.success = true;
      state.error = "";
      programTemplatesSlice.caseReducers.handleRestDay(state, {
        payload: action.payload,
      });
    },
    [editProgramTemplate.rejected]: (state, action) => {
      state.saving = false;
      state.success = false;
      state.error = action.payload || action?.error?.message;
    },

    [addProgramTemplate.pending]: (state) => {
      state.loading = true;
    },
    [addProgramTemplate.fulfilled]: (state) => {
      state.loading = false;
      state.successfullyAddTemplate = true;
      state.error = "";
    },
    [addProgramTemplate.rejected]: (state, action) => {
      state.loading = false;
      state.successfullyAddTemplate = false;
      state.error = action.payload || action?.error?.message;
    },

    [deleteProgramTemplate.pending]: (state) => {
      state.isDeleting = true;
    },
    [deleteProgramTemplate.fulfilled]: (state) => {
      state.isDeleting = false;
      state.successfullyDeleteTemplate = true;
      state.error = "";
    },
    [deleteProgramTemplate.rejected]: (state, action) => {
      state.isDeleting = false;
      state.successfullyDeleteTemplate = false;
      state.error = action.payload || EError.default_error;
    },
    [checkTotalReps.pending]: (state) => {
      state.loading = true;
    },
    [checkTotalReps.fulfilled]: (state) => {
      state.checkingSetDetails = false;
      state.loading = false;
    },
    [checkTotalReps.rejected]: (state, action) => {
      state.checkingSetDetails = false;
      state.loading = false;
      state.error = action.payload || EError.default_error;
    },
    [loadProgramPreview.pending]: (state) => {
      state.loading = true;
    },
    [loadProgramPreview.fulfilled]: (state, action) => {
      state.currentPreviewProgram = action.payload;
      state.loading = false;
    },
    [loadProgramPreview.rejected]: (state, action) => {
      state.error = action.payload || EError.default_error;
      state.loading = false;
    },
    [loadHistoryProgramPreview.pending]: (state) => {
      state.loading = true;
    },
    [loadHistoryProgramPreview.fulfilled]: (state, action) => {
      state.currentPreviewProgram = action.payload;
      state.loading = false;
    },
    [loadHistoryProgramPreview.rejected]: (state, action) => {
      state.error = action.payload || EError.default_error;
      state.loading = false;
    },
  },
});

export const {
  clearStatuses,
  clearError,
  clearSuccess,
  clearSuccessfullyAddTemplate,
  clearSuccessfullyDeleteTemplate,
  clearProgramTemplates,
  setItemForDelete,
  clearItemForDelete,
  setChangeAllExercise,
  setSelectedExercise,
  setExerciseSetErrors,
  removeExerciseSetErrors,
  clearExerciseSetErrors,
  setHistory,
  setGroupedByDay,
  handleCheckSetDetails,
  setLoading,
} = programTemplatesSlice.actions;
export default programTemplatesSlice.reducer;
