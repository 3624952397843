import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import update from "lodash/update";
import moment from "moment";

import * as DietsApi from "../api/dietsApi";
import {
  ADD_DIET_ERROR_MESSAGE,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
  INITIAL_FOR_DAYS,
  INITIAL_MEALS_STATE,
  INITIAL_QUANTITY,
  INITIAL_WO,
  LOAD_FROM_TEMPLATE_ERROR_MESSAGE,
  LOAD_FROM_TEMPLATE_SUCCESS_MESSAGE,
  MIN_MEAL_COLUMN_COUNT,
  RESTORE_DIET_ERROR_MESSAGE,
  SHOW_ALL_FOODS_INIT,
} from "../constants/diet";
import { prepareDietState } from "../helpers/diet";
import { EError } from "../constants/enums";
import { UNKNOWN_FAIL } from "../constants/payment";

export const updateDietPlan = createAsyncThunk(
  "diets/updateDietPlan",
  DietsApi.updateDietPlan
);

export const addSecondDiet = createAsyncThunk(
  "diets/addSecondDiet",
  DietsApi.addSecondDiet
);

export const deleteSecondDiet = createAsyncThunk(
  "diets/deleteSecondDiet",
  DietsApi.deleteSecondDiet
);

export const addDietFood = createAsyncThunk(
  "diets/addDietFood",
  DietsApi.addDietFood
);

export const editDietFood = createAsyncThunk(
  "diets/editDietFood",
  DietsApi.editDietFood
);

export const deleteDietFood = createAsyncThunk(
  "diets/deleteDietFood",
  DietsApi.deleteDietFood
);

export const loadDietFromTemplate = createAsyncThunk(
  "diet/loadDietFromTemplate",
  DietsApi.loadDietFromTemplate
);

export const loadDietHistory = createAsyncThunk(
  "diets/loadDietHistory",
  DietsApi.loadDietHistoryWithCache
);

export const restoreDiet = createAsyncThunk(
  "diets/restoreDiet",
  DietsApi.restoreDiet
);

const dietPageSlice = createSlice({
  name: "dietPage",
  initialState: {
    diets: [null, null],
    isShowPrePostWorkout: [false, false],
    mealColumnCount: [0, 0],
    meals: [[], []],
    selectedDietIndex: 0,
    isDoubleDiet: false,
    notes: "",
    isShowAllFoods: SHOW_ALL_FOODS_INIT,
    messages: DEFAULT_SUCCESS_MESSAGE,
    successfullyDietEdited: false,
    error: "",
    loadingHistory: false,
    successfullyHistoryLoaded: false,
    diet_plan_id: 0,
    isDietUpdated: false,
  },
  reducers: {
    initDietPage(state, action) {
      const {
        primary_diet: primaryDiet,
        second_diet: secondDiet,
        is_double_diet: isDoubleDiet,
        notes,
      } = action.payload;

      const diets = [primaryDiet];
      if (isDoubleDiet) {
        diets.push(secondDiet);
      }
      state.notes = notes;
      state.diet_plan_id = action.payload.id;
      state.isDoubleDiet = isDoubleDiet;
      dietPageSlice.caseReducers.prepareDietState(state, {
        payload: diets,
      });
    },
    restoreDiet(state, action) {
      const dietPlan = action.payload;
      if (!dietPlan) return;
      if (!dietPlan.is_double_diet) {
        state.selectedDietIndex = 0;
      }
      dietPageSlice.caseReducers.initDietPage(state, {
        payload: dietPlan,
      });
    },
    setDietFromTemplate(state, action) {
      const { primary_diet, second_diet, is_double_diet, id } = action.payload;
      primary_diet.foods = primary_diet.foods.map((d) => ({
        ...d,
        meals: INITIAL_MEALS_STATE,
        is_pre_post_workout: false,
      }));
      if (is_double_diet) {
        second_diet.foods = second_diet.foods.map((d) => ({
          ...d,
          meals: INITIAL_MEALS_STATE,
          is_pre_post_workout: false,
        }));
        primary_diet.for_days = INITIAL_FOR_DAYS;
        second_diet.for_days = INITIAL_FOR_DAYS;
      } else {
        state.selectedDietIndex = 0;
      }
      state.messages = LOAD_FROM_TEMPLATE_SUCCESS_MESSAGE;
      state.diet_plan_id = id;
      dietPageSlice.caseReducers.initDietPage(state, {
        payload: { ...action.payload, primary_diet, second_diet },
      });
    },
    prepareDietState(state, action) {
      const { diets, mealColumnCount, isShowPrePostWorkout, meals } =
        prepareDietState(action.payload);
      dietPageSlice.caseReducers.setDiets(state, {
        payload: diets,
      });
      dietPageSlice.caseReducers.setMealColumnCount(state, {
        payload: mealColumnCount,
      });
      dietPageSlice.caseReducers.setIsShowPrePostWorkout(state, {
        payload: isShowPrePostWorkout,
      });
      dietPageSlice.caseReducers.setMeals(state, { payload: meals });
    },
    setDiets(state, action) {
      state.diets = action.payload;
    },
    setMealColumnCount(state, action) {
      state.mealColumnCount = action.payload;
    },
    setDefaultMessages(state) {
      state.messages = DEFAULT_SUCCESS_MESSAGE;
    },
    onChangeMealColumnCount(state) {
      if (!state.isShowPrePostWorkout[state.selectedDietIndex]) {
        state.isShowPrePostWorkout[state.selectedDietIndex] = true;
        return;
      }
      state.mealColumnCount[state.selectedDietIndex]++;
    },
    setIsShowPrePostWorkout(state, action) {
      state.isShowPrePostWorkout = action.payload;
    },
    setMeals(state, action) {
      state.meals = action.payload;
    },
    setIsShowAllFoodsForCurrentDiet(state) {
      state.isShowAllFoods[state.selectedDietIndex] = true;
    },
    onEditDiet(state, action) {
      const { diets, meals } = prepareDietState(action.payload);
      dietPageSlice.caseReducers.setDiets(state, {
        payload: diets,
      });
      dietPageSlice.caseReducers.setMeals(state, { payload: meals });
    },
    onChangeQuantity(state, action) {
      const path = `diets[${state.selectedDietIndex}].foods[${action.payload.idxFood}].quantity`;
      const newState = state;
      const value = action.payload.value || "0";
      if (/^\d{0,4}$/.test(value)) {
        update(newState, path, () => Number(value));
        dietPageSlice.caseReducers.onEditDiet(state, {
          payload: newState.diets,
        });
      }
    },
    onChangeFoodMeal(state, action) {
      const path = `diets[${state.selectedDietIndex}].foods[${action.payload.idxFood}].meals[${action.payload.idxMeal}]`;
      const newState = state;
      update(newState, path, (isCheck) => !isCheck);
      dietPageSlice.caseReducers.onEditDiet(state, { payload: newState.diets });
    },
    onChangeFoodWOMeal(state, action) {
      const path = `diets[${state.selectedDietIndex}].foods[${action.payload.idxFood}].is_pre_post_workout`;
      const newState = state;
      update(newState, path, (is_pre_post_workout) => !is_pre_post_workout);
      dietPageSlice.caseReducers.onEditDiet(state, { payload: newState.diets });
    },
    onDeleteFood(state, action) {
      let deleted = false;
      const foods = [...state.diets[state.selectedDietIndex].foods].filter(
        (item) => {
          const res = item.food.id !== action.payload;

          if (deleted) return true;
          if (!res) deleted = true;

          return res;
        }
      );
      state.diets[state.selectedDietIndex].foods = foods;
      if (
        state.selectedDietIndex === 0 &&
        state.diets[1] &&
        foods.length === 0
      ) {
        state.diets[0] = { ...state.diets[1] };
        state.mealColumnCount[0] = state.mealColumnCount[1];
        state.meals[0] = state.meals[1];
        state.isShowPrePostWorkout[0] = state.isShowPrePostWorkout[1];
        state.isShowAllFoods[0] = state.isShowAllFoods[1];
        dietPageSlice.caseReducers.onDeleteDiet(state);
      }
      dietPageSlice.caseReducers.onEditDiet(state, { payload: state.diets });
    },
    onAddFood(state, action) {
      const food = action.payload;
      const foods = [...state.diets[state.selectedDietIndex].foods];
      if (!foods.find((i) => i.food.id === food.id)) {
        foods.push({
          food: food,
          meals: INITIAL_MEALS_STATE,
          is_pre_post_workout: INITIAL_WO,
          quantity: INITIAL_QUANTITY,
          food_id: food.id,
        });
        state.diets[state.selectedDietIndex].foods = foods;
        dietPageSlice.caseReducers.onEditDiet(state, {
          payload: state.diets,
        });
      }
    },
    onCopyDiet(state) {
      state.isDoubleDiet = true;
      state.diets[1] = {
        foods: state.diets[0].foods,
        for_days: INITIAL_FOR_DAYS,
      };
      state.diets[0].for_days = INITIAL_FOR_DAYS;
      state.mealColumnCount[1] = state.mealColumnCount[0];
      state.isShowPrePostWorkout[1] = state.isShowPrePostWorkout[0];
      state.isShowAllFoods[1] = state.isShowAllFoods[0];
      state.meals[1] = [];
      dietPageSlice.caseReducers.onEditDiet(state, {
        payload: state.diets,
      });
    },
    onAddDiet(state) {
      state.isDoubleDiet = true;
      state.diets[0].for_days = INITIAL_FOR_DAYS;
      state.diets[1] = {
        foods: [],
        for_days: INITIAL_FOR_DAYS,
      };
      state.mealColumnCount[1] = MIN_MEAL_COLUMN_COUNT;
      state.isShowPrePostWorkout[1] = false;
      state.isShowAllFoods[1] = SHOW_ALL_FOODS_INIT[1];
      state.meals[1] = [];
    },
    onDeleteDiet(state) {
      state.diets[0].for_days = null;
      state.selectedDietIndex = 0;
      state.diets = state.diets.slice(0, 1);
      state.meals = state.meals.slice(0, 1);
      state.mealColumnCount = state.mealColumnCount.slice(0, 1);
      state.isShowPrePostWorkout = state.isShowPrePostWorkout.slice(0, 1);
      state.isShowAllFoods = state.isShowAllFoods.slice(0, 1);
      state.isDoubleDiet = false;
    },
    onChangeForDays(state, action) {
      state.diets[0].for_days = action.payload.primaryForDays;
      state.diets[1].for_days = action.payload.secondForDays;
    },
    onChangeSelectIndex(state, action) {
      state.selectedDietIndex = action.payload;
    },
    onChangeNotes(state, action) {
      state.notes = action.payload;
    },
    onDefaultFulfill(state) {
      state.successfullyDietEdited = true;
      state.saving = false;
      state.message = DEFAULT_SUCCESS_MESSAGE;
    },
    clearSuccessfullyDietEdited(state) {
      state.successfullyDietEdited = false;
    },
    onDefaultReject(state) {
      state.successfullyDietEdited = false;
      state.saving = false;
      state.error = DEFAULT_ERROR_MESSAGE;
    },
    clearError(state) {
      state.error = "";
    },
    setError(state, action) {
      state.error = action.payload || EError.default_error;
    },
    clearHistory(state) {
      state.history = null;
      state.successfullyDietEdited = false;
    },
    setIsDietUpdatedFalse(state) {
      state.isDietUpdated = false;
    },
    setIsDietUpdatedTrue(state) {
      state.isDietUpdated = true;
    },
  },
  extraReducers: {
    [updateDietPlan.pending]: (state) => {
      state.saving = true;
      state.successfullyDietEdited = false;
    },
    [updateDietPlan.fulfilled]: (state) =>
      dietPageSlice.caseReducers.onDefaultFulfill(state),
    [updateDietPlan.rejected]: (state) =>
      dietPageSlice.caseReducers.onDefaultReject(state),
    [addSecondDiet.pending]: (state, action) => {
      state.saving = true;
      state.successfullyDietEdited = false;
      const { copyFromPrimary } = action.meta.arg;
      if (copyFromPrimary) {
        dietPageSlice.caseReducers.onCopyDiet(state);
      } else {
        dietPageSlice.caseReducers.onAddDiet(state);
      }
    },
    [addSecondDiet.fulfilled]: (state) =>
      dietPageSlice.caseReducers.onDefaultFulfill(state),
    [addSecondDiet.rejected]: (state) => {
      state.successfullyDietEdited = false;
      state.saving = false;
      state.error = ADD_DIET_ERROR_MESSAGE;
      dietPageSlice.caseReducers.onDeleteDiet(state);
    },
    [deleteSecondDiet.pending]: (state) => {
      state.saving = true;
      state.successfullyDietEdited = false;
      dietPageSlice.caseReducers.onDeleteDiet(state);
    },
    [deleteSecondDiet.fulfilled]: (state) =>
      dietPageSlice.caseReducers.onDefaultFulfill(state),
    [deleteSecondDiet.rejected]: (state) =>
      dietPageSlice.caseReducers.onDefaultReject(state),
    [addDietFood.pending]: (state, action) => {
      state.saving = true;
      state.successfullyDietEdited = false;
      const { food } = action.meta.arg;
      dietPageSlice.caseReducers.onAddFood(state, { payload: food });
    },
    [addDietFood.fulfilled]: (state) =>
      dietPageSlice.caseReducers.onDefaultFulfill(state),
    [addDietFood.rejected]: (state) =>
      dietPageSlice.caseReducers.onDefaultReject(state),
    [editDietFood.pending]: (state) => {
      state.saving = true;
      state.successfullyDietEdited = false;
    },
    [editDietFood.fulfilled]: (state) =>
      dietPageSlice.caseReducers.onDefaultFulfill(state),
    [editDietFood.rejected]: (state) =>
      dietPageSlice.caseReducers.onDefaultReject(state),
    [deleteDietFood.pending]: (state) => {
      state.saving = true;
      state.successfullyDietEdited = false;
    },
    [deleteDietFood.fulfilled]: (state, action) => {
      const { foodId } = action.meta.arg;
      dietPageSlice.caseReducers.onDeleteFood(state, { payload: foodId });

      dietPageSlice.caseReducers.onDefaultFulfill(state);
    },
    [deleteDietFood.rejected]: (state) =>
      dietPageSlice.caseReducers.onDefaultReject(state),
    [loadDietFromTemplate.pending]: (state) => {
      state.saving = true;
      state.successfullyDietEdited = false;
    },
    [loadDietFromTemplate.fulfilled]: (state, action) => {
      dietPageSlice.caseReducers.onDefaultFulfill(state);
      dietPageSlice.caseReducers.setDietFromTemplate(state, action);
    },
    [loadDietFromTemplate.rejected]: (state) => {
      state.successfullyDietEdited = false;
      state.saving = false;
      state.error = LOAD_FROM_TEMPLATE_ERROR_MESSAGE;
    },
    [loadDietHistory.pending]: (state) => {
      state.loadingHistory = true;
      state.successfullyHistoryLoaded = false;
    },
    [loadDietHistory.fulfilled]: (state, action) => {
      state.loadingHistory = false;
      state.successfullyHistoryLoaded = true;
      state.error = "";
      state.history = action.payload;
    },
    [loadDietHistory.rejected]: (state, action) => {
      state.loadingHistory = false;
      state.successfullyHistoryLoaded = false;
      state.error = action.error.message || EError.default_error;
    },
    [restoreDiet.pending]: (state) => {
      state.saving = true;
      state.successfullyDietEdited = false;
    },
    [restoreDiet.fulfilled]: (state, action) => {
      const dietPlan = action.payload;
      state.successfullyDietEdited = true;
      state.saving = false;
      state.messages = `DIET FROM ${moment(dietPlan.start_at).format(
        "ll"
      )} RESTORED`;
      dietPageSlice.caseReducers.restoreDiet(state, { payload: dietPlan });
    },
    [restoreDiet.rejected]: (state) => {
      state.successfullyDietEdited = false;
      state.saving = false;
      state.error = RESTORE_DIET_ERROR_MESSAGE;
    },
  },
});

export const {
  onChangeFoodWOMeal,
  onChangeFoodMeal,
  onChangeQuantity,
  onChangeNotes,
  onChangeMealColumnCount,
  setIsShowAllFoodsForCurrentDiet,
  setMealColumnCount,
  setDiets,
  setIsShowPrePostWorkout,
  setMeals,
  initDietPage,
  onChangeSelectIndex,
  onChangeForDays,
  setDefaultMessages,
  clearSuccessfullyDietEdited,
  clearError,
  clearHistory,
  setIsDietUpdatedFalse,
  setIsDietUpdatedTrue,
  setError,
} = dietPageSlice.actions;

export default dietPageSlice.reducer;
