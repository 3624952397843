import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import * as ExercisesApi from "../api/exercisesApi";
import { SIDEBAR_MODE } from "../constants/sidebar";
import addInSortArray from "../helpers/addInSortArray";
import { EError } from "../constants/enums";

export const loadExercises = createAsyncThunk(
  "exercises/loadExercises",
  (args) => ExercisesApi.loadExercisesWithCache.call(args)
);

export const loadExercisesForCurrentUser = createAsyncThunk(
  "exercises/loadExercisesForCurrentUser",
  (args) => ExercisesApi.loadExercisesForCurrentUserWithCache.call(args)
);

export const loadFavExercises = createAsyncThunk(
  "exercises/loadFavExercises",
  (args) => ExercisesApi.loadFavExercisesWithCache(args)
)

export const addExercises = createAsyncThunk(
  "exercises/addExercises",
  async (args, thunkAPI) => {
    const response = await ExercisesApi.addExercises(args);
    thunkAPI.dispatch(loadExercises({}));
    return response;
  }
);

export const editExercises = createAsyncThunk(
  "exercises/editExercises",
  (args) => ExercisesApi.editExercises(args)
);

export const deleteExercises = createAsyncThunk(
  "exercises/deleteExercises",
  (args) => ExercisesApi.deleteExercises(args)
);

const exercisesSlice = createSlice({
  name: "exercises",
  initialState: {
    error: "",
    exercises: null,
    exercisesPage: 1,
    exercisesTotalCount: null,
    count: null,
    next: null,
    previous: null,
    exercisesPerPage: 50,
    itemCurrentUser: null,
    selectedExercises: null,
    sidebarMode: null,
    loading: false,
    successfullyExercisesGet: false,
    successfullyExercisesAdded: false,
    successfullyExercisesEdited: false,
    successfullyExercisesDelete: false,
    favExercisesPage: 1,
  },
  reducers: {
    clearStatuses(state) {
      state.loading = false;
      state.successfullyExercisesGet = false;
      state.successfullyExercisesAdded = false;
      state.successfullyExercisesEdited = false;
      state.successfullyExercisesDelete = false;
    },
    clearSuccessfullyExercisesAdded(state) {
      state.successfullyExercisesAdded = false;
    },
    clearSuccessfullyExercisesEdited(state) {
      state.successfullyExercisesEdited = false;
    },
    clearSuccessfullyExercisesDelete(state) {
      state.successfullyExercisesDelete = false;
    },
    setSelectedExercises(state, action) {
      state.selectedExercises = action.payload;
    },
    setSidebarMode(state, action) {
      state.sidebarMode = action.payload;
    },
    clearSelectedExercises(state) {
      state.selectedExercises = null;
    },
    setExercises(state, action) {
      state.exercises = action.payload;
    },
    setRejected(state, action) {
      state.loading = false;
      state.error = action.payload || EError.default_error;
    },
    clearError(state) {
      state.error = "";
    },
    setFulfilled(state) {
      state.loading = false;
      state.error = "";
    },
    setExercisesPage(state, action) {
      state.exercisesPage = action.payload;
    },
    setFavExercisesPage(state, action) {
      state.favExercisesPage = action.payload;
    },
    setClearTableState(state) {
      state.count = null;
      state.next = null;
      state.previous = null;
      state.exercisesPage = 1;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [loadExercises.pending]: (state) => {
      state.loading = true;
    },
    [loadExercises.fulfilled]: (state, action) => {
      state.successfullyGet = true;
      exercisesSlice.caseReducers.setFulfilled(state);
      exercisesSlice.caseReducers.setExercises(state, action);

      state.count = action.payload?.count;
      state.next = action.payload?.next;
      state.previous = action.payload?.previous;
    },
    [loadExercises.rejected]: (state, action) => {
      exercisesSlice.caseReducers.setRejected(state, action);
      state.successfullyGet = false;

      state.count = null;
      state.next = null;
      state.previous = null;
      state.exercisesPage = 1;
    },
    [loadExercisesForCurrentUser.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [loadExercisesForCurrentUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = "";
      state.itemCurrentUser = action.payload?.results;
      state.count = action.payload?.count;
      state.next = action.payload?.next;
      state.previous = action.payload?.previous;
    },
    [loadExercisesForCurrentUser.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload || EError.default_error;
      state.count = null;
      state.next = null;
      state.previous = null;
      state.exercisesPage = 1;
    },
    [loadFavExercises.pending]: (state) => {
      state.loading = true;
    },
    [loadFavExercises.fulfilled]: (state, action) => {
      state.successfullyGet = true;
      exercisesSlice.caseReducers.setFulfilled(state);
      exercisesSlice.caseReducers.setExercises(state, action);

      state.count = action.payload?.count;
      state.next = action.payload?.next;
      state.previous = action.payload?.previous;
    },
    [loadFavExercises.rejected]: (state, action) => {
      exercisesSlice.caseReducers.setRejected(state, action);
      state.successfullyGet = false;

      state.count = null;
      state.next = null;
      state.previous = null;
      state.exercisesPage = 1;
    },
    [addExercises.pending]: (state) => {
      state.successfullyExercisesAdded = false;
      state.loading = true;
    },
    [addExercises.fulfilled]: (state, action) => {
      exercisesSlice.caseReducers.setFulfilled(state);
      state.successfullyExercisesAdded = true;
      if (state.exercises)
        state.exercises.exercises = addInSortArray(
          state.exercises,
          action.payload
        );
      if (state.itemCurrentUser)
        state.itemCurrentUser = addInSortArray(
          state.itemCurrentUser,
          action.payload
        );
      state.selectedExercises = action.payload;
      state.sidebarMode = SIDEBAR_MODE.EDIT;
    },
    [addExercises.rejected]: (state, action) => {
      exercisesSlice.caseReducers.setRejected(state, action);
      state.successfullyExercisesAdded = false;
    },
    [editExercises.pending]: (state) => {
      state.successfullyExercisesEdited = false;
      state.loading = true;
    },
    [editExercises.fulfilled]: (state, action) => {
      exercisesSlice.caseReducers.setFulfilled(state);
      const mapSelectedToE = (e) => {
        if (!Array.isArray(e)) {
          return;
        }
        return e.map((obj) => {
          if (obj.id === state.selectedExercises.id) {
            const res = {
              ...action.payload,
              id: state.selectedExercises.id,
            };
            state.selectedExercises = res;
            return res;
          }
          return obj;
        });
      };

      if (state.exercises) state.exercises = mapSelectedToE(state.exercises);
      if (state.itemCurrentUser)
        state.itemCurrentUser = mapSelectedToE(state.itemCurrentUser);
      state.successfullyExercisesEdited = true;
    },
    [editExercises.rejected]: (state, action) => {
      exercisesSlice.caseReducers.setRejected(state, action);
      state.successfullyExercisesEdited = false;
    },
    [deleteExercises.pending]: (state) => {
      state.successfullyExercisesDelete = false;
      state.loading = true;
    },
    [deleteExercises.fulfilled]: (state) => {
      state.successfullyExercisesDelete = true;
      exercisesSlice.caseReducers.setFulfilled(state);

      if (state?.exercises?.itemCurrentUser?.results) {
        state.exercises = state.exercises.itemCurrentUser?.results.filter(
          (e) => e.id !== state.selectedExercises.id
        );
      }

      if (state.itemCurrentUser) {
        state.itemCurrentUser = state.itemCurrentUser?.filter(
          (e) => e.id !== state.selectedExercises.id
        );
      }
    },
    [deleteExercises.rejected]: (state, action) => {
      exercisesSlice.caseReducers.setRejected(state, action);
      state.successfullyExercisesDelete = false;
    },
  },
});

export const {
  setSelectedExercises,
  setSidebarMode,
  clearStatuses,
  clearSelectedExercises,
  clearSuccessfullyExercisesAdded,
  clearSuccessfullyExercisesEdited,
  clearSuccessfullyExercisesDelete,
  setExercisesPage,
  setFavExercisesPage,
  setClearTableState,
  setFulfilled,
  setRejected,
  setLoading,
  clearError,
} = exercisesSlice.actions;

export default exercisesSlice.reducer;
