export const HEADER = [
  {
    label: "",
    style: { width: "10%" },
  },
  {
    label: "company",
    style: { width: "20%", textAlign: "left" },
  },
  {
    label: "coach",
    style: { width: "30%", textAlign: "left" },
  },
  {
    label: "amount",
    style: { width: "20%", textAlign: "center" },
  },
  {
    label: "invoice date",
    style: { width: "20%", textAlign: "center" },
  },
];

export const ACTION_PAYMENT_TYPE = {
  SAVE: "save",
  INVOICE_SENT: "invoice sent",
  FINALIZE: "finalize",
};

export const INVOICE_STATUS = {
  DUE: "DUE",
  OVERDUE: "OVERDUE",
  PAID: "PAID",
};

export const PAYMENT_DURATION = 200;

export const PAYMENT_ACCOUNT_REQUEST_DELAY = 3000;

export const CREATE_PAYMENT_ACCOUNT_FULFILLED = "we are trying to save your payment account. please wait a little longer";

export const DELETE_PAYMENT_ACCOUNT_FULFILLED = "we are trying to delete your payment account. please wait a little longer";

export const LOAD_PAYMENT_ACCOUNT_ERROR = "failed to load the payment account";

export const CREATE_PAYMENT_ACCOUNT_ERROR = "failed to create the payment account";

export const DELETE_PAYMENT_ACCOUNT_ERROR = "failed to delete the payment account";

export const CHECK_SESSION_ID_FULFILLED = "your card is successfuly verified";

export const USER_TYPE_COMPANY_ADMIN = "CompanyAdmin";

export const INVOICE_AMOUNT_UPDATE =
  "the invoice amount has been updated. please, try again";

export const UNKNOWN_FAIL = "something went wrong. please, try again";

export const PAYMENT_SUCCESS = "payment has been made";

export const PAYMENT_DESCRIPTION_INVOICES_1 =
  "your recomposer invoices will be emailed to you on the";
export const PAYMENT_DESCRIPTION_INVOICES_2 =
  "of each month and will be charged to your saved card 7 days \nlater.";
export const PAYMENT_DESCRIPTION_NOCARD_1 =
  "you have no saved credit card for payments. your invoices are \ncurrently emailed to";
export const PAYMENT_DESCRIPTION_NOCARD_2 =
  "for payment by bank transfer. enter a card below to switch to \nautomated payments:";
export const AGREE_CHARGE =
  "i agree to recomposer invoices being automatically charged to my \ncard above";

export const AGREE_CONVERTATION_FROM_USD_TO_AED =
  "i understand invoices are calculated in USD but charged in AED";

export const MONTHLY_CHARGES = "* recomposer lite costs just $4.50 US for 30 days";

export const CURRENCY_MASK_OPTIONS = {
  prefix: "$",
  suffix: "",
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2,
  integerLimit: 7,
  allowNegative: false,
  allowLeadingZeroes: false,
  inputMode: "numeric",
};
