import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/react";

import * as CompaniesAPI from "../api/companiesAPI";
import myFetch from "../api/myFetch";
import * as UserApi from "../api/userApi";
import {
  CHECK_SESSION_ID_FULFILLED,
  CREATE_PAYMENT_ACCOUNT_ERROR,
  CREATE_PAYMENT_ACCOUNT_FULFILLED,
  DELETE_PAYMENT_ACCOUNT_ERROR,
  DELETE_PAYMENT_ACCOUNT_FULFILLED,
  LOAD_PAYMENT_ACCOUNT_ERROR,
} from "../constants/payment";
import { EError } from "../constants/enums";

export const loadProfile = createAsyncThunk(
  "user/loadProfile",
  UserApi.loadProfile
);

export const updateProfile = createAsyncThunk(
  "user/loadProfile",
  UserApi.loadProfile
);

export const saveProfile = createAsyncThunk(
  "user/saveProfile",
  UserApi.saveProfile
);

export const loadCSV = createAsyncThunk("user/loadCSV", () => {
  return myFetch("/api/client/csv");
});

export const loadUserPaymentAccount = createAsyncThunk(
  "user/loadPaymentAccount",
  CompaniesAPI.loadPaymentAccount
);

export const loadUserStripePaymentAccount = createAsyncThunk(
  "user/loadPaymentAccount",
  CompaniesAPI.loadStripePaymentAccount
);

export const createUserPaymentAccount = createAsyncThunk(
  "user/createPaymentAccount",
  CompaniesAPI.createPaymentAccount
);

export const createUserStripePaymentAccount = createAsyncThunk(
  "user/createPaymentAccount",
  CompaniesAPI.createStripePaymentAccount
);

export const verifyUserPaymentAccount = createAsyncThunk(
  "user/verifyPaymentAccount",
  CompaniesAPI.verifyPaymentAccount
);

export const deleteUserPaymentAccount = createAsyncThunk(
  "user/deletePaymentAccount",
  CompaniesAPI.deletePaymentAccount
);

export const deleteUserStripePaymentAccount = createAsyncThunk(
  "user/deletePaymentAccount",
  CompaniesAPI.deleteStripePaymentAccount
);

export const checkUserSessionId = createAsyncThunk(
  "user/checkSessionId",
  (args, { rejectWithValue, dispatch }) =>
    UserApi.checkSessionId(args, { rejectWithValue, dispatch })
);

export const payCurrentDue = createAsyncThunk(
  "user/payCurrentDue",
  UserApi.payCurrentDue
);

export const getUsageDaysDeposit = createAsyncThunk(
  "user/getUsageDaysDeposit",
  UserApi.getUsageDaysDeposit
)

const userSlice = createSlice({
  name: "user",
  initialState: {
    loading: false,
    loadingCSV: false,
    success: false,
    successCSV: false,
    successSave: false,
    successSavePassword: false,
    successLoadDeposit: false,
    passwordChanged: false,
    error: null,
    user: null,
    csv: null,
    last4: null,
    message: null,
    cardHolderName: null,
    isTaskFinished: true,
    errorStatusCode: null,
    isCardVerified: false,
    deletePaymentError: false,
    deletePaymentSuccess: false,
    sessionIdVerificationAttempt: 0,
    isPaymentRequestSuccessful: false,
    paymentAccountLoadingAttempted: false,
    haveToSaveCard: false,
    haveToPay: false,
    loadingUpdateProfile: false,
    successUpdateProfile: true,
    errorUpdateProfile: null,
    debt: 0,
    checkDebt: false,
    usageDaysDeposit: null,
    preventUserPaymentAccRemoval: false,
  },
  reducers: {
    clearError(state) {
      state.error = null;
      state.deletePaymentError = false;
    },
    clearSuccessSave(state) {
      state.successSave = false;
      state.successSavePassword = false;
    },
    clearCsv(state) {
      state.csv = null;
    },
    setPasswordChanged(state, action) {
      state.passwordChanged = action.payload;
    },
    setError(state, action) {
      state.error = action.payload || EError.default_error;
    },
    setMessage(state, action) {
      state.message = action.payload;
    },
    clearSuccessLoadInfo(state) {
      state.success = false;
    },
    setUserPaymentAccount(state, action) {
      const { last4, card_holder } = action.payload;
      state.last4 = last4;
      state.cardHolderName = card_holder;
    },
    setStripePaymentAccount(state, action) {
      const { last4, card_holder } = action.payload;
      state.last4 = last4;
      state.cardHolderName = card_holder;
    },
    setErrorStatusCode(state, action) {
      state.errorStatusCode = action.payload;
    },
    setSessionIdVerificationAttempt(state, action) {
      state.sessionIdVerificationAttempt += action.payload;
    },
    setUserPaymentAccountLoadingAttempted(state, action) {
      state.paymentAccountLoadingAttempted = action.payload;
    },
    setCheckDebt(state, action) {
      state.checkDebt = action.payload;
    },
    setUserPaymentAccRemoval(state, action) {
      state.preventUserPaymentAccRemoval = action.payload;
    },
  },
  extraReducers: {
    [loadProfile.pending]: (state) => {
      state.loading = true;
    },
    [loadProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = null;
      const user = action.payload;
      state.user = user;

      state.haveToSaveCard = user?.have_to_save_card;
      state.haveToPay = user?.have_to_pay;

      Sentry.setUser({
        id: user?.id,
        username: user?.full_name,
        email: user?.email,
      });
    },
    [loadProfile.rejected]: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload || EError.default_error;
      Sentry.setUser({});
    },
    [loadCSV.pending]: (state) => {
      state.loadingCSV = true;
      state.error = null;
    },
    [loadCSV.fulfilled]: (state, action) => {
      state.loadingCSV = false;
      state.successCSV = true;
      state.csv = action.payload.data;
    },
    [loadCSV.rejected]: (state, action) => {
      state.loadingCSV = false;
      state.successCSV = false;
      state.error = action.payload || EError.default_error;
    },
    [saveProfile.pending]: (state) => {
      state.successSave = false;
    },
    [saveProfile.fulfilled]: (state, action) => {
      state.error = null;
      state.user = action.payload;
      state.successSave = true;
    },
    [saveProfile.rejected]: (state, action) => {
      state.error = action.error.message || EError.default_error;
    },
    [loadUserPaymentAccount.pending]: (state) => {
      state.isPaymentRequestSuccessful = false;
    },
    [loadUserStripePaymentAccount.pending]: (state) => {
      state.isPaymentRequestSuccessful = false;
    },
    [loadUserPaymentAccount.fulfilled]: (state, action) => {
      state.isPaymentRequestSuccessful = true;
      const {
        is_task_finished: isTaskFinished,
        payment_account_verified,
        debt,
      } = action.payload;
      state.debt = debt || 0;
      state.isCardVerified = payment_account_verified;

      if (!isTaskFinished && state.isTaskFinished) {
        state.message = CREATE_PAYMENT_ACCOUNT_FULFILLED;
      }
      state.isTaskFinished = isTaskFinished;
      userSlice.caseReducers.setUserPaymentAccount(state, action);
    },
    [loadUserStripePaymentAccount.fulfilled]: (state, action) => {
      state.isPaymentRequestSuccessful = true;
      const { is_task_finished, payment_account_verified } = action.payload;
      state.isCardVerified = payment_account_verified;
      state.debt = action.payload.debt || 0;

      if (!is_task_finished && state.isTaskFinished) {
        state.message = CREATE_PAYMENT_ACCOUNT_FULFILLED;
      }
      state.isTaskFinished = is_task_finished;
      userSlice.caseReducers.setStripePaymentAccount(state, action);
    },
    [loadUserPaymentAccount.rejected]: (state) => {
      state.isPaymentRequestSuccessful = false;
      state.error = LOAD_PAYMENT_ACCOUNT_ERROR;
    },
    [loadUserStripePaymentAccount.rejected]: (state) => {
      state.isPaymentRequestSuccessful = false;
      state.error = LOAD_PAYMENT_ACCOUNT_ERROR;
    },
    [createUserPaymentAccount.fulfilled]: (state) => {
      state.isPaymentRequestSuccessful = false;
      state.isTaskFinished = false;
      state.message = CREATE_PAYMENT_ACCOUNT_FULFILLED;
    },
    [createUserPaymentAccount.rejected]: (state) => {
      state.isPaymentRequestSuccessful = false;
      state.error = CREATE_PAYMENT_ACCOUNT_ERROR;
    },
    [createUserStripePaymentAccount.fulfilled]: (state) => {
      state.isPaymentRequestSuccessful = false;
      state.isTaskFinished = false;
      state.message = CREATE_PAYMENT_ACCOUNT_FULFILLED;
    },
    [createUserStripePaymentAccount.pending]: (state) => {
      state.isPaymentRequestSuccessful = false;
    },
    [createUserStripePaymentAccount.rejected]: (state) => {
      state.isPaymentRequestSuccessful = false;
      state.error = CREATE_PAYMENT_ACCOUNT_ERROR;
    },
    [verifyUserPaymentAccount.pending]: (state) => {
      state.isTaskFinished = false;
    },
    [verifyUserPaymentAccount.fulfilled]: (state, action) => {
      const { payment_account_verified, cko_redirect_url } = action.payload;
      if (payment_account_verified) {
        state.message = CHECK_SESSION_ID_FULFILLED;
        state.isCardVerified = true;
        state.isTaskFinished = true;
      } else {
        document.location.replace(cko_redirect_url);
      }
    },
    [verifyUserPaymentAccount.rejected]: (state, action) => {
      state.isTaskFinished = true;
      const { status, error } = action;
      if (+status === 400) {
        return (state.error = error);
      }
      state.error = error.message;
    },
    [deleteUserPaymentAccount.pending]: (state) => {
      state.isPaymentRequestSuccessful = false;
    },
    [deleteUserPaymentAccount.fulfilled]: (state, action) => {
      state.isPaymentRequestSuccessful = true;
      state.deletePaymentSuccess = true;
      state.isTaskFinished = false;
      if (!action.payload.forceDelete)
        state.message = DELETE_PAYMENT_ACCOUNT_FULFILLED;
      userSlice.caseReducers.setUserPaymentAccount(state, {
        payload: {
          last4: null,
          card_holder: null,
        },
      });
    },
    [deleteUserPaymentAccount.rejected]: (state) => {
      state.deletePaymentError = true;
      state.deletePaymentSuccess = false;
      state.isPaymentRequestSuccessful = false;
      state.error = DELETE_PAYMENT_ACCOUNT_ERROR;
    },
    [deleteUserStripePaymentAccount.fulfilled]: (state, action) => {
      state.isPaymentRequestSuccessful = true;
      state.deletePaymentSuccess = true;
      state.isTaskFinished = false;
      if (!action.payload.forceDelete)
        state.message = DELETE_PAYMENT_ACCOUNT_FULFILLED;

      userSlice.caseReducers.setStripePaymentAccount(state, {
        payload: {
          last4: null,
          card_holder: null,
        },
      });
    },
    [deleteUserStripePaymentAccount.pending]: (state) => {
      state.isPaymentRequestSuccessful = false;
    },
    [deleteUserStripePaymentAccount.rejected]: (state) => {
      state.deletePaymentError = true;
      state.deletePaymentSuccess = false;
      state.isPaymentRequestSuccessful = false;
      state.error = DELETE_PAYMENT_ACCOUNT_ERROR;
    },
    [checkUserSessionId.fulfilled]: (state) => {
      state.isCardVerified = true;
      state.message = CHECK_SESSION_ID_FULFILLED;
    },
    [checkUserSessionId.rejected]: (state, action) => {
      state.error = action.payload || EError.default_error;
    },

    [updateProfile.pending]: (state) => {
      state.loadingUpdateProfile = true;
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.loadingUpdateProfile = false;
      state.successUpdateProfile = true;
      state.errorUpdateProfile = null;
      const user = action.payload;
      state.user = user;

      state.haveToSaveCard = user?.have_to_save_card;
      state.haveToPay = user?.have_to_pay;

      Sentry.setUser({
        id: user?.id,
        username: user?.full_name,
        email: user?.email,
      });
    },
    [updateProfile.rejected]: (state, action) => {
      state.loadingUpdateProfile = false;
      state.successUpdateProfile = false;
      state.UpdateProfile = action.payload;
      Sentry.setUser({});
    },
    [payCurrentDue.pending]: (state) => {
      state.isPaymentRequestSuccessful = false;
    },
    [payCurrentDue.fulfilled]: (state) => {
      state.isPaymentRequestSuccessful = true;
      state.checkDebt = true;
    },
    [getUsageDaysDeposit.pending]: (state) => {
      state.successLoadDeposit = false;
    },
    [getUsageDaysDeposit.fulfilled]: (state, action) => {
      state.successLoadDeposit = true;
      state.usageDaysDeposit = action.payload.usage_days_deposit;
    },
  },
});

export const {
  setError,
  clearError,
  clearCsv,
  clearSuccessSave,
  setPasswordChanged,
  setErrorStatusCode,
  clearSuccessLoadInfo,
  setUserPaymentAccount,
  setUserPaymentAccountLoadingAttempted,
  setCheckDebt,
  setMessage,
  setUserPaymentAccRemoval,
} = userSlice.actions;

export default userSlice.reducer;
