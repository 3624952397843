export const res =
    {
      id: 4456,
      name: "2-3_split kayne_norton_phat fixed reps 2 5_weekly 4_weeks",
      start_at: "2023-10-08T21:11:47+11:00",
      end_at: null,
      exercises: [
        {
          id: 80344,
          day_index: 1,
          workout_index: 0,
          exercise_index: 0,
          exercise_id: 22598,
          exercise_name: "squat - bb back",
          rep_calculator: "standard",
          sets: [
            {
              id: 1324396,
              weight: 40,
              reps: 12,
              notes: "",
              other_reps: 0,
              other_reps_notes: "",
              goal_reps: null,
              is_completed: true,
              completed_at: "2023-09-25T07:14:08+10:00",
              has_new_pb: false,
              set_index: 0
            },
            {
              id: 1324397,
              weight: 50,
              reps: 12,
              notes: "",
              other_reps: 0,
              other_reps_notes: "",
              goal_reps: null,
              is_completed: true,
              completed_at: "2023-09-25T07:16:45+10:00",
              has_new_pb: false,
              set_index: 1
            },
            {
              id: 1324398,
              weight: 50,
              reps: 12,
              notes: "",
              other_reps: 0,
              other_reps_notes: "",
              goal_reps: null,
              is_completed: true,
              completed_at: "2023-09-25T07:21:01+10:00",
              has_new_pb: false,
              set_index: 2
            }
          ]
        },
        {
          id: 80345,
          day_index: 1,
          workout_index: 0,
          exercise_index: 1,
          exercise_id: 22635,
          exercise_name: "squat - hack",
          rep_calculator: "standard",
          sets: [
            {
              id: 1324399,
              weight: 60,
              reps: 12,
              notes: "",
              other_reps: 0,
              other_reps_notes: "",
              goal_reps: null,
              is_completed: true,
              completed_at: "2023-09-23T08:32:26+10:00",
              has_new_pb: false,
              set_index: 0
            },
            {
              id: 1324400,
              weight: 60,
              reps: 12,
              notes: "",
              other_reps: 0,
              other_reps_notes: "",
              goal_reps: null,
              is_completed: true,
              completed_at: "2023-09-23T08:35:58+10:00",
              has_new_pb: false,
              set_index: 1
            },
            {
              id: 1324401,
              weight: 70,
              reps: 12,
              notes: "",
              other_reps: 0,
              other_reps_notes: "",
              goal_reps: null,
              is_completed: true,
              completed_at: "2023-09-23T08:40:30+10:00",
              has_new_pb: false,
              set_index: 2
            }
          ]
        },
        {
          id: 80346,
          day_index: 1,
          workout_index: 0,
          exercise_index: 2,
          exercise_id: 22594,
          exercise_name: "leg extension",
          rep_calculator: "leg press/ext",
          sets: [
            {
              id: 1324402,
              weight: 33,
              reps: 12,
              notes: "",
              other_reps: 0,
              other_reps_notes: "",
              goal_reps: null,
              is_completed: true,
              completed_at: "2023-09-23T08:16:18+10:00",
              has_new_pb: false,
              set_index: 0
            },
            {
              id: 1324403,
              weight: 35.5,
              reps: 12,
              notes: "",
              other_reps: 0,
              other_reps_notes: "",
              goal_reps: null,
              is_completed: true,
              completed_at: "2023-09-23T08:16:45+10:00",
              has_new_pb: false,
              set_index: 1
            },
            {
              id: 1324404,
              weight: 38,
              reps: 12,
              notes: "",
              other_reps: 0,
              other_reps_notes: "",
              goal_reps: null,
              is_completed: true,
              completed_at: "2023-09-23T08:18:48+10:00",
              has_new_pb: false,
              set_index: 2
            }
          ]
        },
        {
          id: 80347,
          day_index: 1,
          workout_index: 0,
          exercise_index: 3,
          exercise_id: 22589,
          exercise_name: "deadlift - romanian",
          rep_calculator: "back/bicep",
          sets: [
            {
              id: 1324405,
              weight: 45,
              reps: 12,
              notes: "",
              other_reps: 0,
              other_reps_notes: "",
              goal_reps: null,
              is_completed: true,
              completed_at: "2023-10-04T13:51:25+11:00",
              has_new_pb: false,
              set_index: 0
            },
            {
              id: 1324406,
              weight: 45,
              reps: 12,
              notes: "",
              other_reps: 0,
              other_reps_notes: "",
              goal_reps: null,
              is_completed: true,
              completed_at: "2023-10-04T13:53:11+11:00",
              has_new_pb: false,
              set_index: 1
            },
            {
              id: 1324407,
              weight: 45,
              reps: 12,
              notes: "",
              other_reps: 0,
              other_reps_notes: "",
              goal_reps: null,
              is_completed: true,
              completed_at: "2023-10-04T13:53:33+11:00",
              has_new_pb: false,
              set_index: 2
            }
          ]
        },
        {
          id: 80348,
          day_index: 1,
          workout_index: 0,
          exercise_index: 4,
          exercise_id: 22595,
          exercise_name: "leg curl - lying",
          rep_calculator: "back/bicep",
          sets: [
            {
              id: 1324408,
              weight: 22.5,
              reps: 12,
              notes: "",
              other_reps: 0,
              other_reps_notes: "",
              goal_reps: null,
              is_completed: true,
              completed_at: "2023-09-23T08:11:18+10:00",
              has_new_pb: false,
              set_index: 0
            },
            {
              id: 1324409,
              weight: 22.5,
              reps: 12,
              notes: "",
              other_reps: 0,
              other_reps_notes: "",
              goal_reps: null,
              is_completed: true,
              completed_at: "2023-09-23T08:11:33+10:00",
              has_new_pb: false,
              set_index: 1
            },
            {
              id: 1324410,
              weight: 22.5,
              reps: 12,
              notes: "",
              other_reps: 0,
              other_reps_notes: "",
              goal_reps: null,
              is_completed: true,
              completed_at: "2023-09-23T08:11:53+10:00",
              has_new_pb: false,
              set_index: 2
            }
          ]
        },
        {
          id: 80349,
          day_index: 1,
          workout_index: 0,
          exercise_index: 5,
          exercise_id: 22644,
          exercise_name: "calf raise - standing",
          rep_calculator: "leg press/ext",
          sets: [
            {
              id: 1324411,
              weight: 95,
              reps: 15,
              notes: "",
              other_reps: 0,
              other_reps_notes: "",
              goal_reps: null,
              is_completed: true,
              completed_at: "2023-09-23T08:23:25+10:00",
              has_new_pb: false,
              set_index: 0
            },
            {
              id: 1324412,
              weight: 105,
              reps: 15,
              notes: "",
              other_reps: 0,
              other_reps_notes: "",
              goal_reps: null,
              is_completed: true,
              completed_at: "2023-09-23T08:23:44+10:00",
              has_new_pb: false,
              set_index: 1
            },
            {
              id: 1324413,
              weight: 107.5,
              reps: 15,
              notes: "",
              other_reps: 0,
              other_reps_notes: "",
              goal_reps: null,
              is_completed: true,
              completed_at: "2023-09-23T08:25:18+10:00",
              has_new_pb: false,
              set_index: 2
            }
          ]
        },
        {
          id: 80350,
          day_index: 1,
          workout_index: 0,
          exercise_index: 6,
          exercise_id: 22645,
          exercise_name: "calf raise - seated",
          rep_calculator: "leg press/ext",
          sets: [
            {
              id: 1324414,
              weight: 41,
              reps: 12,
              notes: "30kg x12 reps",
              other_reps: 0,
              other_reps_notes: "",
              goal_reps: null,
              is_completed: true,
              completed_at: "2023-09-23T08:00:55+10:00",
              has_new_pb: false,
              set_index: 0
            },
            {
              id: 1324415,
              weight: 41,
              reps: 12,
              notes: "",
              other_reps: 0,
              other_reps_notes: "",
              goal_reps: null,
              is_completed: true,
              completed_at: "2023-09-23T08:01:31+10:00",
              has_new_pb: false,
              set_index: 1
            },
            {
              id: 1324416,
              weight: 41,
              reps: 12,
              notes: "",
              other_reps: 0,
              other_reps_notes: "",
              goal_reps: null,
              is_completed: true,
              completed_at: "2023-09-23T08:01:40+10:00",
              has_new_pb: false,
              set_index: 2
            }
          ]
        },
      ],
      notes: []
    }

export const WORKOUT_SCROLL_OFFSET_TOP_M = -90;
export const WORKOUT_SCROLL_OFFSET_TOP_L = -290;
export const NO_WORKOUT_SCROLL_OFFSET = 0;

export const WIDGET_TYPES = {
  PERFORMANCE_AND_HISTORY: "performance-and-history",
  EXERCISE_HISTORY: "exercise-history",
  NOTES: "notes",
  EDIT_EXERCISE: "edit-exercise",
  ADD_PROGRAM: "add-program",
  PROGRAM_PREVIEW: "program-preview",
  BROWSE: "browse",
};
